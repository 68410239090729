import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import ConvertionTable from "./ConvertionTable";

const Convertion = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Convertion
            </Text>
          </Box>
        </HStack>

        <ConvertionTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Convertion;
