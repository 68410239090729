import { createSlice } from "@reduxjs/toolkit";

export const loading = createSlice({
  name: "loading",
  initialState: {
    loading: false,
  },
  reducers: {
    changeLoading: (state, action) => {
      const item = action.payload;
      state.loading = item;
    },
  },
});

export const { changeLoading } = loading.actions;

export default loading.reducer;
