import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/activityLog/Index";
import MyIcon from "../../../component/myIcon/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
const DetailConvertion = () => {
  const { get } = useHttp();
  const location = useLocation();
  const { state } = location;
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    category: "",
    code: "",
    total: 0,
    store: {
      name: "",
      code: "",
    },
    Convertion_details: [],
    activity_logs: [],
  });
  const [dataProduct, setdataProduct] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setisLoading] = useState(true);

  const fetchData = () => {
    console.log(state);
    get({
      url:
        URL.getListConvertionURL +
        "/" +
        productId.id +
        "?store_id=" +
        state.store_id,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdata(res.data.data);
      setisLoading(false);

      setdataProduct(res.data.data.product_conversion_details);
    });
  };
  useEffect(() => {
    fetchData();
  }, [productId]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"} color="black">
              Convertion {data.transaction_no}
            </Text>
          </Box>
          <Button colorScheme="teal" onClick={onOpen}>
            <HStack>
              <MyIcon name={"notepad-text"} size={14} />
              <Text
                fontSize={FONTSIZES.md}
                fontFamily={"SemiBold"}
                color="white"
              >
                Show Log
              </Text>
            </HStack>
          </Button>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <HStack spacing={10} flex={1} alignItems={"flex-start"}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Store
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={data.store.name}
                      />
                    </Box>
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={2} alignItems={"center"}>
                  <Box flex={0.1}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                      Code Store
                    </Text>
                  </Box>
                  <Box flex={0.8}>
                    <Input
                      fontSize={FONTSIZES.md}
                      readOnly
                      fontFamily={"SemiBold"}
                      defaultValue={data.store.code}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={2} alignItems={"center"}>
                  <Box flex={0.1}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                      Address
                    </Text>
                  </Box>
                  <Box flex={0.8}>
                    <Input
                      fontSize={FONTSIZES.md}
                      readOnly
                      fontFamily={"SemiBold"}
                      defaultValue={data.store.address}
                    />
                  </Box>
                </HStack>
              </Stack>
              <Stack flex={3} py={4} spacing={5}>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Date
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={formatedDate(data.transaction_date)}
                      />
                    </Box>
                  </HStack>
                </HStack>

                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Item Detail
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <InputGroup>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"SemiBold"}
                          value={data.product_conversion_details?.length}
                        />
                      </InputGroup>
                    </Box>
                  </HStack>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
      </Box>
      <Box bg="white" p={5} borderRadius={5} mt={5}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"} color="black">
              Product Convertion
            </Text>
          </Box>
        </HStack>
        <Box bg="teal.500" borderTopRadius={5} p={5}>
          <HStack flex={1}>
            <HStack flex={1} spacing={10} alignItems={"center"}>
              <Box flex={0.2}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  Product Code
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  Product
                </Text>
              </Box>

              <Box flex={0.2}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  Quantity
                </Text>
              </Box>
              <Box flex={0.1}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  COGS
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  COGS/UNIT
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text
                  fontSize={FONTSIZES.md}
                  color={"white"}
                  fontFamily={"SemiBold"}
                >
                  Price
                </Text>
              </Box>
            </HStack>
          </HStack>
        </Box>
        <Stack borderWidth={1} borderBottomRadius={5}>
          <HStack p="5" borderBottomWidth={1} flex={1}>
            <HStack flex={1} spacing={10} alignItems={"center"}>
              <Box flex={0.2}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  {data.product?.code}
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  {data.product?.name}
                </Text>
              </Box>

              <Box flex={0.2}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  {data.quantity}
                </Text>
              </Box>
              <Box flex={0.1}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  Rp. {formattedCurrency(data.cogs)}
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  Rp {formattedCurrency(data.cogs_per_unit)}
                </Text>
              </Box>
              <Box flex={0.2}>
                <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                  Rp {formattedCurrency(data.price)}
                </Text>
              </Box>
            </HStack>
          </HStack>
        </Stack>
      </Box>
      {/* Detail */}
      {isLoading ? (
        <MyLoader />
      ) : (
        <Box bg="white" p={5} borderRadius={5} mt={5}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text
                fontSize={FONTSIZES.md}
                fontFamily={"SemiBold"}
                color="black"
              >
                Detail Convertion
              </Text>
            </Box>
          </HStack>
          <Box bg="teal.500" borderTopRadius={5} p={5}>
            <HStack flex={1}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Product Code
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Product
                  </Text>
                </Box>

                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Quantity
                  </Text>
                </Box>
                <Box flex={0.1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    COGS
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Total
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>
          <Stack borderWidth={1} borderBottomRadius={5}>
            {dataProduct.map((item, index) => {
              return (
                <HStack p="5" borderBottomWidth={1} flex={1} key={index}>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.product.code}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.product.name}
                      </Text>
                    </Box>

                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.quantity}
                      </Text>
                    </Box>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp. {formattedCurrency(item.cogs)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.total)}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              );
            })}
          </Stack>
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log sale : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item, index) => {
              return <ActivityLogs item={item} key={index} index={index} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailConvertion;
