import numeral from "numeral";

export const formattedCurrency = (number) => {
  return numeral(parseFloat(number)).format("Rp 0,0");
};
// export const formattedCurrencyDot = (number) => {
//   return numeral(parseFloat(number)).format("Rp 0,0");
// };

export const formattedCurrencyDot = (value) => {
  return new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
