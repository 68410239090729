import { Breadcrumb, BreadcrumbItem, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import ICChevronRight from "../../assets/icons/ICChevronRight";
import React from "react";
import { FONTSIZES } from "../../theme/FontSize";

const BreadCumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split("/").filter((x) => x);

  return (
    <Breadcrumb
      spacing="8px"
      separator={<ICChevronRight size={14} color="black" />}
    >
      <BreadcrumbItem>
        <Link to="/">
          <Text
            color={pathname !== "/" ? "teal.500" : "black"}
            fontFamily={"Medium"}
            fontSize={FONTSIZES.md}
          >
            Home
          </Text>
        </Link>
      </BreadcrumbItem>

      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        const capitalized = name
          .replace(/[^a-zA-Z0-9\s]/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return (
          <BreadcrumbItem key={routeTo}>
            {isLast ? (
              <Text fontFamily={"Medium"} fontSize={FONTSIZES.md}>
                {capitalized}
              </Text>
            ) : (
              <Link to={routeTo}>
                <Text
                  color={"teal.500"}
                  fontFamily={"Medium"}
                  fontSize={FONTSIZES.md}
                >
                  {capitalized}
                </Text>
              </Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCumb;
