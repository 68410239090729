import { configureStore } from "@reduxjs/toolkit";
import UserDataReducer from "./UserDataReducer.js";
import ProductReducer from "./ProductReducer.js";
import LoadingReducer from "./LoadingReducer.js";
export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: {
    userData: UserDataReducer,
    product: ProductReducer,
    loading: LoadingReducer,
  },
});
