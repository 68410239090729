import { Box, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import Title from "./Title/Title";
import Header from "./header/Header";
import Preview from "./preview/Preview";

const Pricelist = () => {
  const [data, setdata] = useState([]);
  return (
    <VStack spacing={5} alignItems={"flex-start"}>
      <Title />
      <Header setdata={setdata} data={data} />
      <Preview data={data} />
    </VStack>
  );
};

export default Pricelist;
