import { Box, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import { UploadCloud, XCircle } from "lucide-react";
import React, { useRef, useState, useTransition } from "react";
import ExceljS from "exceljs";
import { useDispatch, useSelector } from "react-redux";
import { changeLoading } from "../../../../utils/redux/LoadingReducer";
const Upload = ({ setdata, data }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const [isDisabled, setisDisabled] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const files = useRef(null);
  // const handleUpload = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsText(file);
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const csvData = data.split("\n");
  //     const headers = csvData[0].split(",");
  //     const jsonData = csvData
  //       .slice(1)
  //       .map((row) => {
  //         const obj = {};
  //         row.split(",").forEach((cell, i) => {
  //           if (cell.trim() !== "") {
  //             obj[headers[i].trim()] = cell.trim();
  //           }
  //         });
  //         if (Object.keys(obj).length !== 0) {
  //           return obj;
  //         }
  //       })
  //       .filter((obj) => obj);
  //     setdata(jsonData);
  //     setisDisabled(false);
  //   };
  // };
  const convertToJSON = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const workbook = new ExceljS.Workbook();
    await workbook.xlsx.load(file).then(() => {
      const worksheet = workbook.worksheets[0];
      const jsonData = [];
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return; // skip header
        const rowObj = {};
        row.eachCell((cell, colNumber) => {
          const cellValue = cell.value;
          const headerValue = worksheet.getRow(1).getCell(colNumber).value;
          rowObj[headerValue] = cellValue;
        });
        if (rowObj["Price"] && rowObj["Price"] !== 0) {
          // skip rows with Price 0 or empty string
          jsonData.push(rowObj);
        }
      });

      setdata(jsonData);

      setisDisabled(false);
    });
  };

  const handleUpload = (e) => {
    setisLoading(true);
    convertToJSON(e).finally(() => {
      setisLoading(false);
    });
  };

  const handleClear = () => {
    dispatch(changeLoading(true));
    setTimeout(() => {
      files.current.value = "";
      setisDisabled(true);
      setdata([]);
      setisLoading(false);
    }, 500);
  };

  return (
    <HStack flex={1} justifyContent={"space-between"}>
      <>
        <input
          disabled={files?.current?.files[0]?.name.length > 0}
          type="file"
          id="file"
          style={{ display: "none" }}
          onChange={handleUpload}
          ref={files}
        />
        <label style={{ cursor: "pointer" }} htmlFor="file">
          <Button
            isDisabled={files?.current?.files[0]?.name.length > 0}
            colorScheme="blue"
            as="span"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <HStack spacing={2}>
                <UploadCloud size={16} />
                <Text fontFamily={"Medium"}>
                  {files?.current?.files[0]?.name.length > 0
                    ? files?.current?.files[0]?.name
                    : "Upload"}
                </Text>
              </HStack>
            )}
          </Button>
        </label>
      </>
      <Button
        isDisabled={!files?.current?.files[0]?.name.length > 0 || loading}
        colorScheme="red"
        onClick={handleClear}
      >
        {loading ? (
          <Spinner />
        ) : (
          <HStack>
            <XCircle size={16} />
            <Text fontFamily={"Medium"}>Clear</Text>
          </HStack>
        )}
      </Button>
    </HStack>
  );
};

export default Upload;
