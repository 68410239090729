import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState, useTransition } from "react";
import {
  formattedCurrency,
  formattedCurrencyDot,
} from "../../../../utils/formatedCurrency";
import Logo from "../../../../assets/images/LogoGomoFresh.png";
import TagImg from "../../../../assets/images/tag.png";
import TagImgPromo from "../../../../assets/images/tag-promo.png";
const ListData = ({ data }) => {
  return (
    <Box>
      {data.map((batch, pageIndex) => (
        <HStack
          alignItems={"flex-start"}
          justify={"center"}
          key={pageIndex}
          w="210mm"
          h="297mm"
          bg={"white"}
          borderRadius="10px"
          boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
          mb={10}
        >
          <HStack
            w={"171mm"}
            py="10"
            alignItems={"center"}
            wrap={"wrap"}
            spacing={0}
          >
            {batch.map((item, index) => (
              <VStack
                spacing={0}
                key={index}
                justify={"space-between"}
                w="57mm"
                h="37mm"
              >
                <Box borderWidth={1} position={"absolute"} w="57mm" h="37mm">
                  <Image src={TagImg} position={"absolute"} w="57mm" h="37mm" />
                </Box>

                <Box zIndex={20}>
                  <HStack
                    pt={0}
                    spacing={1}
                    alignItems={"flex-start"}
                    justify={"center"}
                  >
                    <Text
                      pt={"10pt"}
                      fontFamily={"extraBold"}
                      fontSize={"9.01pt"}
                    >
                      RP
                    </Text>
                    <Text fontFamily={"PoppinsBlack"} fontSize={"33.94pt"}>
                      {formattedCurrencyDot(item?.Price)}
                    </Text>
                  </HStack>
                  <Box w="57mm" mt={"-3mm"} h={"14.5mm"}>
                    <Text
                      px={"1mm"}
                      noOfLines={3}
                      letterSpacing={"0"}
                      textAlign={"center"}
                      fontFamily={"PoppinsBold"}
                      fontSize={"9.01pt"}
                    >
                      {item.Product}
                    </Text>
                  </Box>
                </Box>
              </VStack>
            ))}
          </HStack>
        </HStack>
      ))}
    </Box>
  );
};

export default ListData;
