import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DownloadCloud } from "lucide-react";
import React, { useState, useTransition } from "react";
import { useHttp } from "../../../../utils/http";
import { URL } from "../../../../utils/appURL";
import { AsyncSelect } from "chakra-react-select";
import ExcelJS from "exceljs";
import formatedDate from "../../../../utils/formatedDate";
import { saveAs } from "file-saver";
const Download = () => {
  const [isLoading, setisLoading] = useState(false);
  const [selectedStore, setselectedStore] = useState(null);
  const { get } = useHttp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  const exportToExcel = async (data) => {
    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet();

    ws.getColumn(1).width = 10;
    ws.getColumn(2).width = 30;
    ws.getColumn(3).width = 50;
    ws.getColumn(4).width = 30;

    const rowHeader = ws.getRow(1);

    for (let i = 1; i <= 4; i++) {
      rowHeader.getCell(i).border = {
        top: { style: "Regular" },
        left: { style: "Regular" },
        bottom: { style: "Regular" },
        right: { style: "Regular" },
      };

      rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: "white",
      };

      rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: "black",
      };
    }

    rowHeader.getCell(1).value = "No";
    rowHeader.getCell(2).value = "Product Code";
    rowHeader.getCell(3).value = "Product";
    rowHeader.getCell(4).value = "Price";

    let no = 1;
    for (let i = 1; i <= data?.length; i++) {
      const originalPrice = data[i - 1]?.pricelist?.price || 0;

      const row = ws.getRow(i + 1);
      row.getCell(1).value = no;
      row.getCell(2).value = data[i - 1]?.code;
      row.getCell(3).value = data[i - 1]?.name;
      row.getCell(4).value = originalPrice;

      no++;
    }

    const buf = await wb.xlsx.writeBuffer();

    try {
      saveAs(
        new Blob([buf]),
        `Format Tag Price - ${formatedDate(new Date())}.xlsx`
      );
      onClose();
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };
  const downloadTemplate = async () => {
    setisLoading(true);
    let dataInventory = [];
    let page = 1;
    let newData = [];

    await get({
      url:
        URL.getListProductURL + `?store_id=${selectedStore?.id}&is_inventory=1`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const totalPage = response?.data?.data?.last_page;
      newData = response?.data?.data?.product;

      exportToExcel(newData);
    });
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="teal">
        <HStack spacing={2}>
          <DownloadCloud size={16} />
          <Text fontFamily={"Medium"}>Download Template</Text>
        </HStack>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"SemiBold"} fontSize={"xl"}>
              Select Store
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Stack>
                <Text fontFamily={"SemiBold"} fontSize={16}>
                  Store
                </Text>
                <Box>
                  <AsyncSelect
                    isClearable
                    value={selectedStore}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => setselectedStore(e)}
                    cacheOptions={false}
                    loadOptions={loadOptionsStore}
                    defaultOptions={false}
                  />
                </Box>
              </Stack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!selectedStore || isLoading}
              onClick={downloadTemplate}
              colorScheme="teal"
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <HStack spacing={2}>
                  <DownloadCloud size={16} />
                  <Text fontFamily={"Medium"}>Download Template</Text>
                </HStack>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Download;
