import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import ICChevronLeft from "../../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../../assets/icons/ICChevronRight";
import ActivityLogs from "../../../component/activityLog/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import Inventory from "../../../component/inventory/Index";
import MyLoader from "../../../component/loader/MyLoader";
import MovementStock from "../../../component/movementStock/Index";
import MyIcon from "../../../component/myIcon/Index";
import Pricelist from "../../../component/priceList/Index";
import { FONTSIZES } from "../../../theme/FontSize";

import { URL, baseURLImage } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const DetailProduct = () => {
  const { get, post } = useHttp();
  const parameter = useParams();
  const [isStockAddition, setisStockAddition] = useState(0);
  const [showToCustomer, setshowToCustomer] = useState(0);
  const productId = parameter?.id;
  const [data, setdata] = useState({});
  const [selectedCategory, setselectedCategory] = useState({});
  const [selectedSubCategory, setselectedSubCategory] = useState({});
  const [selectedUnit, setselectedUnit] = useState({});
  const [selectedSmallUnit, setselectedSmallUnit] = useState({});
  const [inventory, setinventory] = useState([]);
  const [showInventory, setshowInventory] = useState(false);
  const [priceList, setpriceList] = useState([]);
  const [showPriceList, setshowPriceList] = useState(false);
  const [movementStock, setmovementStock] = useState([]);
  const [showMovementStock, setshowMovementStock] = useState(false);
  const [activityLog, setactivityLog] = useState([]);
  const [showActivityLog, setshowActivityLog] = useState(false);
  const [store, setStore] = useState([{ name: "All", id: null }]);
  const [modalPagination, setmodalPagination] = useState({
    usePagination: false,
    currentPage: 1,
    totalPage: 1,
  });
  const [isActive, setisActive] = useState(false);
  const [isPoint, setisPoint] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingModal, setisLoadingModal] = useState(true);

  const loadOptionsCategory = (inputValue) => {
    return fetchDataCategory(inputValue);
  };

  const handleCategory = (input) => {
    setselectedCategory(input);
    setselectedSubCategory({ id: "", name: "Pilih" });
  };

  const loadOptionsSubCategory = (inputValue) => {
    return fetchDataSubCategory(inputValue);
  };

  const loadOptionsUnit = (inputValue) => {
    return fetchDataUnit(inputValue);
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = [{ name: "All", id: null }, ...res.data.data.data];
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  const handleInventory = (open = true) => {
    if (open === true) {
      fetchDataInventory();
    } else {
      setStore([{ name: "All", id: null }]);
      setinventory([]);
    }
    setshowInventory(open);
  };
  const handlePricelist = (open = true) => {
    if (open === true) {
      fetchDataPriceList();
    } else {
      setStore([{ name: "All", id: null }]);
      setpriceList([]);
    }
    setshowPriceList(open);
  };
  const handleMovementStock = (open = true) => {
    if (open === true) {
      fetchDataMovementStock();
    } else {
      setStore([{ name: "All", id: null }]);
      setmovementStock([]);
    }
    setshowMovementStock(open);
  };

  const handlePagination = (page) => {
    if (page < 1) {
      page = 1;
    }
    if (page > modalPagination.totalPage) {
      page = modalPagination.totalPage;
    }

    if (showInventory === true) {
      fetchDataInventory(page);
    }
    if (showPriceList === true) {
      fetchDataPriceList(page);
    }
    if (showMovementStock === true) {
      fetchDataMovementStock(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 3;
    const left = modalPagination.currentPage - delta;
    const right = modalPagination.currentPage + delta + 1;

    for (let i = 1; i <= modalPagination.totalPage; i++) {
      if (
        i === 1 ||
        i === modalPagination.totalPage ||
        (i >= left && i < right)
      ) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant={modalPagination.currentPage === i ? "solid" : "ghost"}
            isDisabled={
              modalPagination.currentPage === i || isLoadingModal === true
            }
            onClick={() => handlePagination(i)}
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              {i}
            </Text>
          </Button>
        );
      } else if (i === left - 1 || i === right + 1) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant="ghost"
            disabled
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              ...
            </Text>
          </Button>
        );
      }
    }
    return pageNumbers;
  };

  const PaginationNav = () => {
    return (
      <HStack justify="flex-end" spacing={1}>
        {modalPagination.totalPage > 1 && (
          <>
            <Button
              size={["xs", "md"]}
              key={"first"}
              colorScheme="teal"
              variant={modalPagination.currentPage === 1 ? "solid" : "ghost"}
              isDisabled={
                modalPagination.currentPage === 1 || isLoadingModal === true
              }
              onClick={() => handlePagination(1)}
            >
              <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
                {"First"}
              </Text>
            </Button>
            <IconButton
              size={["xs", "md"]}
              aria-label="previous page"
              icon={<ICChevronLeft color="black" size="18" />}
              colorScheme="teal"
              variant="ghost"
              isDisabled={
                modalPagination.currentPage === 1 || isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.currentPage - 1)}
            />
          </>
        )}
        {renderPageNumbers()}
        {modalPagination.totalPage > 1 && (
          <>
            <IconButton
              size={["xs", "md"]}
              aria-label="next page"
              icon={<ICChevronRight color="black" size="18" />}
              colorScheme="teal"
              variant="ghost"
              isDisabled={
                modalPagination.currentPage === modalPagination.totalPage ||
                isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.currentPage + 1)}
            />
            <Button
              size={["xs", "md"]}
              key={"last"}
              colorScheme="teal"
              variant={
                modalPagination.currentPage === modalPagination.totalPage
                  ? "solid"
                  : "ghost"
              }
              isDisabled={
                modalPagination.currentPage === modalPagination.totalPage ||
                isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.totalPage)}
            >
              <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
                {"Last"}
              </Text>
            </Button>
          </>
        )}
      </HStack>
    );
  };

  const fetchDataProduct = async () => {
    setisLoading(true);
    get({
      url: URL.getListProductURL + "/" + productId,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        setdata(response?.data?.data);
        setisStockAddition(response?.data?.data?.is_stock_addition);
        setshowToCustomer(response?.data?.data?.show_to_customer);
        setisActive(response?.data?.data?.is_active);
        setisPoint(response?.data?.data?.is_point);
        setselectedCategory(response?.data?.data?.product_category);
        setselectedSubCategory(response?.data?.data?.product_subcategory);
        setselectedUnit(response?.data?.data?.unit);
        setselectedSmallUnit(response?.data?.data?.small_unit);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
      });
  };

  const fetchDataCategory = async (input) => {
    let category = [];
    let currentPage = 0;
    let looping = true;
    while (looping == true) {
      currentPage++;
      await get({
        url:
          URL.getListProductCategoryURL +
          `?search=${input}&page=${currentPage}`,
        needAuth: true,
        showToast: false,
      })
        .then((response) => {
          const totalPage = response?.data?.data?.last_page;
          const newData = response?.data?.data?.data;
          category.push(...newData);
          if (currentPage === totalPage) {
            looping = false;
          }
        })
        .catch((error) => {
          looping = false;
        });
    }
    return category;
  };

  const fetchDataSubCategory = async (input) => {
    let subcategory = [];
    let currentPage = 0;
    let looping = true;
    while (looping == true) {
      currentPage++;
      await get({
        url:
          URL.getListProductSubCategoryURL +
          `/category/${selectedCategory?.code}?search=${input}`,
        needAuth: true,
        showToast: false,
      })
        .then((response) => {
          const totalPage = response?.data?.data?.last_page;
          const newData = response?.data?.data;
          subcategory.push(...newData);
          looping = false;
        })
        .catch((error) => {
          looping = false;
        });
    }
    return subcategory;
  };

  const fetchDataInventory = async (page = 1) => {
    setisLoadingModal(true);
    get({
      url: URL.getInventoryURL + `/${productId}?page=${page}`,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const totalPage = response?.data?.data?.last_page;
        const newData = response?.data?.data?.data;
        if (totalPage) {
          setmodalPagination({
            usePagination: true,
            currentPage: page,
            totalPage: totalPage,
          });
        }
        setinventory(newData);
        setisLoadingModal(false);
      })
      .catch((error) => {
        setisLoadingModal(false);
      });
  };

  const fetchDataPriceList = async (page = 1) => {
    setisLoadingModal(true);
    get({
      url:
        URL.getPriceListURL +
        `/${productId}?page=${page}${store.id ? `&store_id=${store.id}` : ``}`,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const totalPage = response?.data?.data?.last_page;
        const newData = response?.data?.data?.data;
        if (totalPage) {
          setmodalPagination({
            usePagination: true,
            currentPage: page,
            totalPage: totalPage,
          });
        }
        setpriceList(newData);
        setisLoadingModal(false);
      })
      .catch((error) => {
        setisLoadingModal(false);
      });
  };

  const fetchDataUnit = async (input) => {
    let unit = [];
    let currentPage = 0;
    let looping = true;
    while (looping == true) {
      currentPage++;
      await get({
        url: URL.getListProductUnitURL + `?search=${input}&page=${currentPage}`,
        needAuth: true,
        showToast: false,
      })
        .then((response) => {
          const totalPage = response?.data?.data?.last_page;
          const newData = response?.data?.data?.data;
          unit.push(...newData);
          if (currentPage === totalPage) {
            looping = false;
          }
        })
        .catch((error) => {
          looping = false;
        });
    }
    return unit;
  };

  const fetchDataMovementStock = async (page = 1) => {
    setisLoadingModal(true);
    get({
      url:
        URL.getMovementStockURL +
        `/${productId}?page=${page}${store.id ? `&store_id=${store.id}` : ``}`,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const totalPage = response?.data?.data?.last_page;
        const newData = response?.data?.data?.data;
        if (totalPage) {
          setmodalPagination({
            usePagination: true,
            currentPage: page,
            totalPage: totalPage,
          });
        }
        setmovementStock(newData);
        setisLoadingModal(false);
      })
      .catch((error) => {
        setisLoadingModal(false);
      });
  };

  const handleActivityLog = (open = true) => {
    setshowActivityLog(open);
    setisLoadingModal(false);
  };

  const handleChangeStockAddition = (e) => {
    post({
      url: URL.postConfigurationUrl,
      data: {
        configurations: [
          {
            code: data?.code,
            is_stock_addition: e ? 1 : 0,
            show_to_customer: showToCustomer,
          },
        ],
      },
      needAuth: true,
      showToast: true,
    })
      .then((res) => {
        setisStockAddition(e ? 1 : 0);
      })
      .catch((err) => {
        setisStockAddition(isStockAddition);
      });
  };
  const handleShowCustomer = (e) => {
    post({
      url: URL.postConfigurationUrl,
      data: {
        configurations: [
          {
            code: data?.code,
            is_stock_addition: isStockAddition,
            show_to_customer: e ? 1 : 0,
          },
        ],
      },
      needAuth: true,
      showToast: true,
    })
      .then((res) => {
        setshowToCustomer(e ? 1 : 0);
      })
      .catch((err) => {
        setshowToCustomer(isStockAddition);
      });
  };

  useEffect(() => {
    fetchDataProduct();
  }, [productId]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={FONTSIZES.lg} fontFamily={"Semibold"} color="black">
              Detail Product
            </Text>
          </Box>
          <HStack>
            {/* Button show modal inventory */}
            <Button colorScheme="green" onClick={() => handleInventory(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Inventory
                </Text>
              </HStack>
            </Button>
            {/* Button show modal pricelist */}
            <Button colorScheme="blue" onClick={() => handlePricelist(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Pricelist
                </Text>
              </HStack>
            </Button>
            {/* Button show modal stock movement */}
            <Button
              colorScheme="orange"
              onClick={() => handleMovementStock(true)}
            >
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Movement Stock
                </Text>
              </HStack>
            </Button>
            {/* Button show modal activity log */}
            <Button colorScheme="teal" onClick={() => handleActivityLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <>
            <Box borderWidth={1} borderRadius={5} p={5}>
              <Text fontFamily={"Semibold"} fontSize={FONTSIZES.lg}>
                Detail Product
              </Text>
              <HStack flex={1}>
                <Stack flex={3} py={4} spacing={5}>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                        Code
                      </Text>
                    </Box>
                    <Box flex={0.7}>
                      <Input
                        fontSize={FONTSIZES.md}
                        required
                        readOnly={isDisable}
                        fontFamily={"Regular"}
                        defaultValue={data?.code}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                        Name
                      </Text>
                    </Box>
                    <Box flex={0.7}>
                      <Input
                        fontSize={FONTSIZES.md}
                        required
                        readOnly={isDisable}
                        fontFamily={"Regular"}
                        defaultValue={data?.name}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                        Category
                      </Text>
                    </Box>
                    <Box flex={0.7}>
                      <AsyncSelect
                        key="category"
                        isDisabled={isDisable}
                        value={selectedCategory}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => handleCategory(e)}
                        loadOptions={loadOptionsCategory}
                        defaultOptions={false}
                        cacheOptions={false}
                        hideSelectedOptions={false}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                        Sub Category
                      </Text>
                    </Box>
                    <Box flex={0.7}>
                      <AsyncSelect
                        key="subcategory"
                        isDisabled={isDisable}
                        value={selectedSubCategory}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => setselectedSubCategory(e)}
                        loadOptions={loadOptionsSubCategory}
                        defaultOptions={false}
                        cacheOptions={false}
                        hideSelectedOptions={false}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}></Box>
                    <HStack flex={0.7} justify={"flex-end"}>
                      <HStack>
                        <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                          Stock Addition
                        </Text>
                        <Checkbox
                          isChecked={isStockAddition}
                          onChange={(e) =>
                            handleChangeStockAddition(e.target.checked)
                          }
                        ></Checkbox>
                      </HStack>
                      <HStack>
                        <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                          Customer Order
                        </Text>
                        <Checkbox
                          isChecked={showToCustomer}
                          onChange={(e) => handleShowCustomer(e.target.checked)}
                        ></Checkbox>
                      </HStack>
                    </HStack>
                  </HStack>
                </Stack>
                <Stack flex={1}>
                  <Image
                    boxShadow="xl"
                    boxSize="full"
                    objectFit="cover"
                    rounded={"md"}
                    src={`${baseURLImage}/${data?.image}`}
                    alt="logo"
                    fallback={<Skeleton boxSize="full" />}
                  />
                </Stack>
              </HStack>
            </Box>
            <Box mt={4} borderWidth={1} borderRadius={5} p={5}>
              <Text fontFamily={"Semibold"} fontSize={FONTSIZES.lg}>
                Sub Detail Product
              </Text>
              <Stack spacing={4} py={4}>
                <HStack spacing={4} alignItems={"center"}>
                  <Box flex={1}>
                    <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                      Unit
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                      Small Unit
                    </Text>
                  </Box>
                  <Box flex={0.5}>
                    <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                      Point
                    </Text>
                  </Box>
                  <Box flex={0.5}>
                    <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                      Active
                    </Text>
                  </Box>
                </HStack>
                <HStack spacing={4} alignItems={"center"}>
                  <Box flex={1}>
                    <AsyncSelect
                      key="unit"
                      isDisabled={isDisable}
                      value={selectedUnit}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setselectedUnit(e)}
                      loadOptions={loadOptionsUnit}
                      defaultOptions={false}
                      cacheOptions={false}
                      hideSelectedOptions={false}
                    />
                  </Box>
                  <Box flex={1}>
                    <AsyncSelect
                      key="small_unit"
                      isDisabled={isDisable}
                      value={selectedSmallUnit}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setselectedSmallUnit(e)}
                      loadOptions={loadOptionsUnit}
                      defaultOptions={false}
                      cacheOptions={false}
                      hideSelectedOptions={false}
                    />
                  </Box>
                  <Box flex={0.5}>
                    <Switch
                      isDisabled={true}
                      isChecked={isPoint == 1 ? true : false}
                    />
                  </Box>
                  <Box flex={0.5}>
                    <Switch
                      isDisabled={true}
                      isChecked={isActive == 1 ? true : false}
                    />
                  </Box>
                </HStack>
              </Stack>
            </Box>
          </>
        )}
      </Box>
      {/* Modal Inventory */}
      <Modal
        isOpen={showInventory}
        onClose={() => handleInventory(false)}
        size={"xxl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Inventory Product {data?.name}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody borderBottomWidth={"1px"}>
            <Box flex={1} bg="white" pb="4" rounded={10}>
              <Box bg="teal.500" borderTopRadius={5} p={2}>
                <HStack flex={1}>
                  <HStack flex={1} alignItems={"center"}>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Store
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"center"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Stock
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Last Update
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              </Box>
              <Box borderBottomRadius={5}>
                {isLoadingModal ? (
                  <Center>
                    <Spinner
                      mt={10}
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="teal.500"
                      size="xl"
                    />
                  </Center>
                ) : inventory.length > 0 ? (
                  <Inventory data={inventory} />
                ) : (
                  <Stack mt={5}>
                    <Center>
                      <Text fontFamily={"Semibold"}>Data Not Found</Text>
                    </Center>
                    <Lottie
                      options={{
                        animationData: require("../../../assets/lotties/empty.json"),
                      }}
                      width={"20vw"}
                    />
                  </Stack>
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <PaginationNav />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal Pricelist */}
      <Modal
        isOpen={showPriceList}
        onClose={() => {
          setStore([{ name: "All", id: null }]);
          setshowPriceList(false);
        }}
        size={"xxl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Pricelist product : {data?.name}
            </Text>
            <HStack pt={5} alignSelf={"flex-start"}>
              <HStack flex={1}>
                <Text fontSize={"large"} fontFamily={"Semibold"}>
                  Store :{" "}
                </Text>
                <Box flex={0.5}>
                  <AsyncSelect
                    value={store.value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => setStore(e)}
                    cacheOptions={false}
                    loadOptions={loadOptionsStore}
                    defaultOptions={false}
                  />
                </Box>
              </HStack>
              <Button
                colorScheme="teal"
                ml={5}
                size={["xs", "md"]}
                onClick={() => fetchDataPriceList(1)}
              >
                Submit
              </Button>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody borderBottomWidth={"1px"}>
            <Box flex={1} bg="white" pb="4" rounded={10}>
              <Box bg="teal.500" borderTopRadius={5} p={2}>
                <HStack flex={1}>
                  <HStack flex={1} alignItems={"center"}>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Date
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Store
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Price
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              </Box>
              <Box borderBottomRadius={5}>
                {isLoadingModal ? (
                  <Center>
                    <Spinner
                      mt={10}
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="teal.500"
                      size="xl"
                    />
                  </Center>
                ) : priceList.length > 0 ? (
                  <Pricelist data={priceList} />
                ) : (
                  <Stack mt={5}>
                    <Center>
                      <Text fontFamily={"Semibold"}>Data Not Found</Text>
                    </Center>
                    <Lottie
                      options={{
                        animationData: require("../../../assets/lotties/empty.json"),
                      }}
                      width={"20vw"}
                    />
                  </Stack>
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <PaginationNav />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal Movement Stock */}
      <Modal
        isOpen={showMovementStock}
        onClose={() => {
          setStore([{ name: "All", id: null }]);
          setshowMovementStock(false);
        }}
        size={"xxl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Movement Stock {data?.name}
            </Text>
            <HStack pt={5} alignSelf={"flex-start"}>
              <HStack flex={1}>
                <Text fontSize={"large"} fontFamily={"Semibold"}>
                  Store :{" "}
                </Text>
                <Box flex={0.5}>
                  <AsyncSelect
                    value={store.value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => setStore(e)}
                    cacheOptions={false}
                    loadOptions={loadOptionsStore}
                    defaultOptions={false}
                  />
                </Box>
              </HStack>
              <Button
                colorScheme="teal"
                ml={5}
                size={["xs", "md"]}
                onClick={() => fetchDataMovementStock(1)}
              >
                Submit
              </Button>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody borderBottomWidth="1px">
            <Box flex={1} bg="white" pb="4" rounded={10}>
              <Box bg="teal.500" borderTopRadius={5} p={2}>
                <HStack flex={1}>
                  <HStack flex={1} alignItems={"center"}>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Date
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Store
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Module
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"center"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Beginning Stock
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"center"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Movement Stock
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"center"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        End Stock
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text
                        fontSize={FONTSIZES.md}
                        textAlign={"left"}
                        color={"white"}
                        fontFamily={"SemiBold"}
                      >
                        Description
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              </Box>

              <Box borderBottomRadius={5}>
                {isLoadingModal ? (
                  <Center>
                    <Spinner
                      mt={10}
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="teal.500"
                      size="xl"
                    />
                  </Center>
                ) : movementStock.length > 0 ? (
                  <MovementStock data={movementStock} />
                ) : (
                  <Stack mt={5}>
                    <Center>
                      <Text fontFamily={"Semibold"}>Data Not Found</Text>
                    </Center>
                    <Lottie
                      options={{
                        animationData: require("../../../assets/lotties/empty.json"),
                      }}
                      width={"20vw"}
                    />
                  </Stack>
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <PaginationNav />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal Activity Log */}
      <Modal
        isOpen={showActivityLog}
        onClose={() => handleActivityLog(false)}
        size={"xxl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log product : {data?.name}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoadingModal ? (
              <Center>
                <Spinner
                  mt={10}
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="teal.500"
                  size="xl"
                />
              </Center>
            ) : activityLog.length > 0 ? (
              <ActivityLogs data={activityLog} />
            ) : (
              <Stack mt={5}>
                <Center>
                  <Text fontFamily={"Semibold"}>Data Not Found</Text>
                </Center>
                <Lottie
                  options={{
                    animationData: require("../../../assets/lotties/empty.json"),
                  }}
                  width={"20vw"}
                />
              </Stack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};

export default DetailProduct;
