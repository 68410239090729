import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as React from "react";
import ICFilter from "../../../assets/icons/ICFilter";

import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { AsyncSelect, Select } from "chakra-react-select";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Lottie from "react-lottie";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/myIcon/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { baseURL, URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
import formatedDateTime from "../../../utils/formatedDateTime";
const dataType = [
  { id: "trans", title: "Transaction" },
  { id: "product", title: "Product" },
  { id: "customer", title: "Customer" },
  { id: "cashier", title: "Cashier" },
  { id: "payment_method", title: "Payment Method" },
  { id: "total", title: "Total" },
];
const ReportSale = ({ label }) => {
  const { get } = useHttp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noTransaction, setnoTransaction] = useState("");

  const [data, setData] = useState({ nodes: [] });
  const [type, settype] = useState("");
  const [totalSales, settotalSales] = useState(0);

  const [filter, setfilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    storeId: "",
  });

  const [selectedStore, setselectedStore] = useState("");

  const [currentPage, setcurrentPage] = useState(1);

  const WW = window.innerWidth;
  const [sizesColumn, setSizesColumn] = useState("200px");

  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customProduct = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${sizesColumn} ${"300px"} ${sizesColumn}  ${sizesColumn} ${"300px"}  ${sizesColumn}  ${"300px"}  ${"600px"} ${"300px"}  ${sizesColumn} ${sizesColumn} ${"300px"}  ${sizesColumn} ${"300px"}  ${sizesColumn} ${sizesColumn}  ${"600px"} ${sizesColumn}  ${sizesColumn}  ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn} ;
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const costumCostumer = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${sizesColumn}  ${sizesColumn} ${"300px"}   ${sizesColumn}   ${sizesColumn} ${"250px"}  ${sizesColumn}   ${"400px"}  ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}    ;
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const customPaymentMethod = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${"300px"}  ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn};
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const customTotal = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      }  ${"300px"}   ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn};
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const customTrans = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      }
        ${sizesColumn}  ${"300px"}  ${sizesColumn}
        ${sizesColumn}  ${sizesColumn}  ${sizesColumn}
      ${sizesColumn}   ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn}  ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}   ${sizesColumn} ;
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const [customTheme, setcustomTheme] = useState(customTrans);
  const theme = [chakraTheme, customTheme];
  const [isLoading, setisLoading] = useState(true);
  const fetchData = () => {
    setisLoading(true);
    const apiUrl = `${baseURL}/report/sales?transaction_no=${noTransaction}&store_id=${
      selectedStore ? selectedStore.id : ""
    }&from_date=${format(filter.startDate, "yyyy-MM-dd")}&to_date=${format(
      filter.endDate,
      "yyyy-MM-dd"
    )}&type=${type.value}`;
    get({
      url:
        URL.getReportSalesURL +
        `?transaction_no=${noTransaction}&store_id=${
          selectedStore ? selectedStore.id : ""
        }&from_date=${format(filter.startDate, "yyyy-MM-dd")}&to_date=${format(
          filter.endDate,
          "yyyy-MM-dd"
        )}&type=${type.value}`,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const a = response.data.data.sales_list;
        settotalSales(response.data.data);

        const flattenedData = a.map(flattenObject);

        const keys = Object.keys(flattenedData[0] || {});

        const headerString = keys.join(",") + "\n";
        const rowString = flattenedData
          .map((d) => {
            const mappedData = keys.map((key) => {
              return d[key] || "";
            });

            return `${mappedData.join(",")}\n`;
          })
          .join("");

        setData({
          nodes: a,
        });
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        if (
          ![
            "created_at",
            "created_by",
            "updated_at",
            "updated_by",
            "deleted_at",
            "deleted_by",
          ].includes(k)
        ) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        }
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});

  const handleType = (e) => {
    setData({ nodes: [] });
    settype(e);
  };

  const exportToExcel = async () => {
    if (type.value === "trans") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;
      ws.getColumn(18).width = 30;
      ws.getColumn(19).width = 30;
      ws.getColumn(20).width = 30;
      ws.getColumn(21).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 21; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Cashier";
      rowHeader.getCell(4).value = "Store Code";
      rowHeader.getCell(5).value = "Store Name";
      rowHeader.getCell(6).value = "Store Address";
      rowHeader.getCell(7).value = "Member";
      rowHeader.getCell(8).value = "Payment Method";
      rowHeader.getCell(9).value = "Source";
      rowHeader.getCell(10).value = "Delivery Type";
      rowHeader.getCell(11).value = "Item Count";
      rowHeader.getCell(12).value = "Point";
      rowHeader.getCell(13).value = "Total";
      rowHeader.getCell(14).value = "Shipping Cost";
      rowHeader.getCell(15).value = "Tax";
      rowHeader.getCell(16).value = "Voucher Code";
      rowHeader.getCell(17).value = "Voucher";
      rowHeader.getCell(18).value = "Grand Total";
      rowHeader.getCell(19).value = "Total Payment";
      rowHeader.getCell(20).value = "Total Cash";
      rowHeader.getCell(21).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].transaction_no;
        row.getCell(2).value = formatedDateTime(
          data.nodes[i - 1].transaction_date
        );
        row.getCell(3).value = data.nodes[i - 1].created_person.person.name;
        row.getCell(4).value = data.nodes[i - 1].store.code;
        row.getCell(5).value = data.nodes[i - 1].store.name;
        row.getCell(6).value = data.nodes[i - 1].store.address;
        row.getCell(7).value = data.nodes[i - 1].person
          ? data.nodes[i - 1].person.name
          : "";
        row.getCell(8).value = data.nodes[i - 1].payment_method.name;
        row.getCell(9).value = data.nodes[i - 1].source;
        row.getCell(10).value = data.nodes[i - 1].delivery_type.toUpperCase();
        row.getCell(11).value = data.nodes[i - 1].sales_details_count;
        row.getCell(12).value = data.nodes[i - 1].point;
        row.getCell(13).value = data.nodes[i - 1].total;
        row.getCell(14).value = data.nodes[i - 1].delivery_fee || 0;
        row.getCell(15).value = data.nodes[i - 1].tax || 0;
        row.getCell(16).value = `${
          data.nodes[i - 1].voucher_code ? data.nodes[i - 1].voucher_code : "-"
        }  ${data.nodes[i - 1].discount_type === "percentage" ? "(%)" : ""}`;
        row.getCell(17).value = data.nodes[i - 1].nominal_voucher;
        row.getCell(18).value = data.nodes[i - 1].grand_total;
        row.getCell(19).value = data.nodes[i - 1].payment_detail?.pay;
        row.getCell(20).value = data.nodes[i - 1].payment_detail?.total_cash;
        row.getCell(21).value = data.nodes[i - 1].payment_detail?.total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(12).value = totalSales.sum_sales_point;
      rowFooter.getCell(13).value = totalSales.sum_sales_total;
      rowFooter.getCell(14).value = totalSales.sum_sales_delivery_fee;
      rowFooter.getCell(15).value = totalSales.sum_sales_tax;
      rowFooter.getCell(17).value = totalSales.sum_sales_nominal_voucher;
      rowFooter.getCell(18).value = totalSales.sum_sales_grand_total;
      rowFooter.getCell(19).value = totalSales.sum_sales_total_payment;
      rowFooter.getCell(20).value = totalSales.sum_sales_total_cash;
      rowFooter.getCell(21).value = totalSales.sum_sales_total_change;
      rowFooter.getCell(12).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(13).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(14).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(15).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(17).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(18).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(19).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(20).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(21).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "customer") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 17; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Member Code";
      rowHeader.getCell(2).value = "Member Name";
      rowHeader.getCell(3).value = "Date";
      rowHeader.getCell(4).value = "Email";
      rowHeader.getCell(5).value = "Phone";
      rowHeader.getCell(6).value = "Store Code";
      rowHeader.getCell(7).value = "Store Name";
      rowHeader.getCell(8).value = "Store Address";
      rowHeader.getCell(9).value = "Sales Count";
      rowHeader.getCell(10).value = "Total";
      rowHeader.getCell(11).value = "Tax";
      rowHeader.getCell(12).value = "Shipping Cost";
      rowHeader.getCell(13).value = "Voucher";
      rowHeader.getCell(14).value = "Grand Total";
      rowHeader.getCell(15).value = "Grand Total";
      rowHeader.getCell(16).value = "Grand Total";
      rowHeader.getCell(17).value = "Grand Total";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].code;
        row.getCell(2).value = data.nodes[i - 1].name;
        row.getCell(3).value = formatedDate(data.nodes[i - 1].created_at);
        row.getCell(4).value = data.nodes[i - 1].email;
        row.getCell(5).value = data.nodes[i - 1].phone_number;
        row.getCell(6).value = data.nodes[i - 1].store.code;
        row.getCell(7).value = data.nodes[i - 1].store.name;
        row.getCell(8).value = data.nodes[i - 1].store.address;
        row.getCell(9).value = data.nodes[i - 1].sales_count;
        row.getCell(10).value = data.nodes[i - 1].sales_sum_total;
        row.getCell(11).value = data.nodes[i - 1].sales_sum_tax;
        row.getCell(12).value = data.nodes[i - 1].sales_sum_delivery_fee;
        row.getCell(13).value =
          data.nodes[i - 1].sales_sum_nominal_voucher || 0;
        row.getCell(14).value = data.nodes[i - 1].sales_sum_grand_total;
        row.getCell(15).value = data.nodes[i - 1].payment_header_sum_total_pay;
        row.getCell(16).value = data.nodes[i - 1].payment_header_sum_total_cash;
        row.getCell(17).value =
          data.nodes[i - 1].payment_header_sum_total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(10).value = totalSales.sum_sales_total;
      rowFooter.getCell(11).value = totalSales.sum_sales_tax;
      rowFooter.getCell(12).value = totalSales.sum_sales_delivery_fee;

      rowFooter.getCell(13).value = totalSales.sum_sales_nominal_voucher;
      rowFooter.getCell(14).value = totalSales.sum_sales_grand_total;
      rowFooter.getCell(15).value = totalSales.sum_sales_total_payment;

      rowFooter.getCell(16).value = totalSales.sum_sales_total_cash;
      rowFooter.getCell(17).value = totalSales.sum_sales_total_change;

      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(11).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(12).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(13).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(14).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(15).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(16).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(17).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sales - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "product") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;
      ws.getColumn(16).width = 30;
      ws.getColumn(17).width = 30;
      ws.getColumn(18).width = 30;
      ws.getColumn(19).width = 30;
      ws.getColumn(20).width = 30;
      ws.getColumn(21).width = 30;
      ws.getColumn(22).width = 30;
      ws.getColumn(23).width = 30;
      ws.getColumn(24).width = 30;
      ws.getColumn(25).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 25; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Member Code";
      rowHeader.getCell(4).value = "Member Name";
      rowHeader.getCell(5).value = "Cashier";
      rowHeader.getCell(6).value = "Store Code";
      rowHeader.getCell(7).value = "Store Name";
      rowHeader.getCell(8).value = "Store Address";
      rowHeader.getCell(9).value = "Payment Method";
      rowHeader.getCell(10).value = "Source";
      rowHeader.getCell(11).value = "Delivery Type";
      rowHeader.getCell(12).value = "Product Division";
      rowHeader.getCell(13).value = "Category Name";
      rowHeader.getCell(14).value = "SubCategory Name";
      rowHeader.getCell(15).value = "Brand";
      rowHeader.getCell(16).value = "Product Code";
      rowHeader.getCell(17).value = "Product Name";
      rowHeader.getCell(18).value = "Price";
      rowHeader.getCell(19).value = "Quantity";
      rowHeader.getCell(20).value = "Unit";
      rowHeader.getCell(21).value = "Total";
      rowHeader.getCell(22).value = "Discount";
      rowHeader.getCell(23).value = "Grand Total";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].sales_header.transaction_no;
        row.getCell(2).value = formatedDateTime(
          data.nodes[i - 1].sales_header.transaction_date
        );
        row.getCell(3).value = data.nodes[i - 1].sales_header.person?.code;
        row.getCell(4).value = data.nodes[i - 1].sales_header.person?.name;
        row.getCell(5).value =
          data.nodes[i - 1].sales_header.created_person?.person?.name;
        row.getCell(6).value = data.nodes[i - 1].sales_header.store.code;
        row.getCell(7).value = data.nodes[i - 1].sales_header.store.name;
        row.getCell(8).value = data.nodes[i - 1].sales_header.store.address;
        row.getCell(9).value =
          data.nodes[i - 1].sales_header.payment_method.name;
        row.getCell(10).value = data.nodes[i - 1].sales_header?.source;
        row.getCell(11).value =
          data.nodes[i - 1].sales_header?.delivery_type.toUpperCase();
        row.getCell(12).value =
          data.nodes[i - 1].product?.product_division?.name;

        row.getCell(13).value =
          data.nodes[i - 1].product?.product_category?.name;

        row.getCell(14).value =
          data.nodes[i - 1].product?.product_subcategory?.name;
        row.getCell(15).value = data.nodes[i - 1].product?.product_brand?.name;
        row.getCell(16).value = data.nodes[i - 1].product?.code;
        row.getCell(17).value = data.nodes[i - 1].product.name;
        row.getCell(18).value = data.nodes[i - 1].price;
        row.getCell(19).value = data.nodes[i - 1].quantity;
        row.getCell(20).value = data.nodes[i - 1].product.unit.name;
        row.getCell(21).value = data.nodes[i - 1].total;
        row.getCell(22).value = data.nodes[i - 1].discount;
        row.getCell(23).value = data.nodes[i - 1].subtotal;

        no++;
      }
      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(19).value = totalSales.sum_sales_quantity;
      rowFooter.getCell(21).value = totalSales.sum_sales_total;

      rowFooter.getCell(22).value = totalSales.sum_sales_discount;
      rowFooter.getCell(23).value = totalSales.sum_sales_subtotal;

      rowFooter.getCell(19).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(21).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(22).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(23).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "payment_method") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 10; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Payment Method";
      rowHeader.getCell(5).value = "Total Nota";
      rowHeader.getCell(6).value = "Total Product";
      rowHeader.getCell(7).value = "Total Sales";
      rowHeader.getCell(8).value = "Total Payment";
      rowHeader.getCell(9).value = "Total Cash";
      rowHeader.getCell(10).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(
          data.nodes[i - 1].transaction_date
        );
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].name;
        row.getCell(5).value = data.nodes[i - 1].total_nota;
        row.getCell(6).value = data.nodes[i - 1].total_product;
        row.getCell(7).value = data.nodes[i - 1].total_sales;
        row.getCell(8).value = data.nodes[i - 1].total_payment;
        row.getCell(9).value = data.nodes[i - 1].total_cash;
        row.getCell(10).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(5).value = totalSales.total_nota;
      rowFooter.getCell(6).value = totalSales.total_product;
      rowFooter.getCell(7).value = totalSales.total_sales;
      rowFooter.getCell(8).value = totalSales.total_payment;
      rowFooter.getCell(9).value = totalSales.total_cash;
      rowFooter.getCell(10).value = totalSales.total_change;
      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "cashier") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 10; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Cashier";
      rowHeader.getCell(5).value = "Total Nota";
      rowHeader.getCell(6).value = "Total Product";
      rowHeader.getCell(7).value = "Total Sales";
      rowHeader.getCell(8).value = "Total Payment";
      rowHeader.getCell(9).value = "Total Cash";
      rowHeader.getCell(10).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(
          data.nodes[i - 1].transaction_date
        );
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].name;
        row.getCell(5).value = data.nodes[i - 1].total_nota;
        row.getCell(6).value = data.nodes[i - 1].total_product;
        row.getCell(7).value = data.nodes[i - 1].total_sales;
        row.getCell(8).value = data.nodes[i - 1].total_payment;
        row.getCell(9).value = data.nodes[i - 1].total_cash;
        row.getCell(10).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(5).value = totalSales.total_nota;
      rowFooter.getCell(6).value = totalSales.total_product;
      rowFooter.getCell(7).value = totalSales.total_sales;
      rowFooter.getCell(8).value = totalSales.total_payment;
      rowFooter.getCell(9).value = totalSales.total_cash;
      rowFooter.getCell(10).value = totalSales.total_change;

      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else if (type.value === "total") {
      const wb = new ExcelJS.Workbook();

      const ws = wb.addWorksheet();

      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;

      const rowHeader = ws.getRow(1);

      for (let i = 1; i <= 9; i++) {
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };

        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };

        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      rowHeader.getCell(1).value = "Transaction Date";
      rowHeader.getCell(2).value = "Store Name";
      rowHeader.getCell(3).value = "Store Address";
      rowHeader.getCell(4).value = "Total Nota";
      rowHeader.getCell(5).value = "Total Product";
      rowHeader.getCell(6).value = "Total Sales";
      rowHeader.getCell(7).value = "Total Payment";
      rowHeader.getCell(8).value = "Total Cash";
      rowHeader.getCell(9).value = "Total Change";

      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = formatedDate(
          data.nodes[i - 1].transaction_date
        );
        row.getCell(2).value = data.nodes[i - 1].store;
        row.getCell(3).value = data.nodes[i - 1].address;
        row.getCell(4).value = data.nodes[i - 1].total_nota;
        row.getCell(5).value = data.nodes[i - 1].total_product;
        row.getCell(6).value = data.nodes[i - 1].total_sales;
        row.getCell(7).value = data.nodes[i - 1].total_payment;
        row.getCell(8).value = data.nodes[i - 1].total_cash;
        row.getCell(9).value = data.nodes[i - 1].total_change;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);
      rowFooter.getCell(4).value = totalSales.total_nota;
      rowFooter.getCell(5).value = totalSales.total_product;
      rowFooter.getCell(6).value = totalSales.total_sales;
      rowFooter.getCell(7).value = totalSales.total_payment;
      rowFooter.getCell(8).value = totalSales.total_cash;
      rowFooter.getCell(9).value = totalSales.total_change;

      rowFooter.getCell(4).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(5).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(6).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(7).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(8).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };

      const buf = await wb.xlsx.writeBuffer();

      saveAs(
        new Blob([buf]),
        `Report Sale - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    }
  };
  setTimeout(() => {
    setisLoading(false);
  }, 700);
  useEffect(() => {
    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("200px");
    }
  }, [currentPage]);
  useEffect(() => {
    setnoTransaction("");
    if (type.value === "trans") {
      setcustomTheme(customTrans);
    } else if (type.value === "product") {
      setcustomTheme(customProduct);
    } else if (type.value === "customer") {
      setcustomTheme(costumCostumer);
    } else if (type.value === "total") {
      setcustomTheme(customTotal);
    } else {
      setcustomTheme(customPaymentMethod);
    }
  }, [type]);

  return (
    <Box bg="white" p="5" rounded={"md"}>
      <Box mb={5}>
        <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
          Sales
        </Text>
      </Box>
      <Wrap spacing={8}>
        <WrapItem zIndex={6}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Type
            </Text>
            <Box w="250px">
              <Select
                value={type}
                onChange={handleType}
                options={dataType.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </Box>
          </Stack>
        </WrapItem>

        <WrapItem zIndex={5}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Start Date
            </Text>

            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.startDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    startDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>
        <WrapItem zIndex={2}>
          <Stack>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              End Date
            </Text>
            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.endDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    endDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack>
            <Text fontSize={FONTSIZES.md} color="white" fontFamily={"SemiBold"}>
              Period
            </Text>
            <Button
              isDisabled={!type}
              w="20"
              colorScheme="teal"
              onClick={fetchData}
            >
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Filter
              </Text>
            </Button>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack display={{ base: "flex" }}>
            <Text color="white" fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Period
            </Text>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <ICFilter size="20" color="white" />
                <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                  Advance Filter
                </Text>
              </HStack>
            </Button>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  <Text fontFamily={"Semibold"}>Advance Filter</Text>
                </DrawerHeader>
                <DrawerBody>
                  <Stack spacing="24px">
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Type
                      </Text>
                      <Box w="250px">
                        <Select
                          value={type}
                          onChange={handleType}
                          options={dataType.map((item) => ({
                            value: item.id,
                            label: item.title,
                          }))}
                        />
                      </Box>
                    </Stack>
                    {type.value !== "customer" && (
                      <Box>
                        <Stack>
                          <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                            Search
                          </Text>
                          <Box>
                            <Input
                              onChange={(e) => setnoTransaction(e.target.value)}
                              fontFamily={"Regular"}
                              id="username"
                              placeholder="Search Transaction No"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Start Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.startDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              startDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        End Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.endDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              endDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Box>
                      <Stack>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Store
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedStore}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedStore(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsStore}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  <Button variant="outline" mr={3} onClick={onClose}>
                    <Text fontFamily={"Semibold"}>Cancel</Text>
                  </Button>
                  <Button
                    isDisabled={!type}
                    colorScheme="teal"
                    onClick={fetchData}
                  >
                    <Text fontFamily={"Semibold"}>Submit</Text>
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Stack>
        </WrapItem>

        {data.nodes?.length > 0 && (
          <WrapItem justifyContent={"flex-end"} flex={1}>
            <HStack>
              <Stack display={{ base: "flex" }}>
                <Text
                  fontSize={FONTSIZES.md}
                  color="white"
                  fontFamily={"SemiBold"}
                >
                  Period
                </Text>

                <Button w="30" colorScheme="teal" onClick={exportToExcel}>
                  <HStack>
                    <MyIcon name={"file-down"} size={18} color="white" />
                    <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                      Export
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </HStack>
          </WrapItem>
        )}
      </Wrap>
      <br />
      {type.value === "trans" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Cashier
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Method
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Source
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Delivery Type
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Item
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Point
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Shipping Cost
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Tax
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Voucher Code
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Voucher
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Grand Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Cash
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Change
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDateTime(item.transaction_date)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item?.created_person?.person?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.person?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.payment_method?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.source}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.delivery_type.toUpperCase()}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_details_count} Item
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.point)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.total)}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.delivery_fee)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.tax)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.voucher_code ? item.voucher_code : "-"}
                            {item.discount_type === "percentage" && "(%)"}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.nominal_voucher)}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.grand_total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item?.payment_detail?.pay)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(
                              item?.payment_detail?.total_cash
                            )}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(
                              item?.payment_detail?.total_change
                            )}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.sum_sales_point)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp {formattedCurrency(totalSales.sum_sales_total)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(totalSales.sum_sales_delivery_fee)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp {formattedCurrency(totalSales.sum_sales_tax)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(
                            totalSales.sum_sales_nominal_voucher
                          )}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(totalSales.sum_sales_grand_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(
                            totalSales.sum_sales_total_payment
                          )}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(totalSales.sum_sales_total_cash)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(totalSales.sum_sales_total_change)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : type.value === "product" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Cashier
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Method
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Source
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Delivery Type
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Product Division
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Category Name
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          SubCategory Name
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Brand
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Product Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Product Name
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Price
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Quantity
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Unit
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Discount
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Grand Total
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.sales_header.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDateTime(
                              item?.sales_header?.transaction_date
                            )}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.sales_header.person?.code}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.sales_header.person?.name}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.created_person?.person?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.sales_header.payment_method.name}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.source}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_header.delivery_type.toUpperCase()}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item?.product?.product_division?.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.product.product_category.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.product.product_subcategory.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item?.product?.product_brand?.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.product.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.product.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.price)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.quantity}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.product.unit.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.discount)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.subtotal)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {totalSales.sum_sales_quantity}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_discount)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_subtotal)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : type.value === "payment_method" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Method
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Nota
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Product
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Sales
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Cash
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Change
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDate(item.transaction_date)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_nota}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_product}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_sales)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_payment)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_cash)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_change)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_nota)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_product)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_sales)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_payment)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_cash)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_change)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : type.value === "cashier" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Cashier
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Nota
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Product
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Sales
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Cash
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Change
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDate(item.transaction_date)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_nota}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_product}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_sales)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_payment)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_cash)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_change)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_nota)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_product)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_sales)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_payment)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_cash)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_change)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : type.value === "total" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Nota
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Product
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Sales
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Cash
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Change
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDate(item.transaction_date)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.address}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_nota}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.total_product}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_sales)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_payment)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_cash)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total_change)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_nota)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_product)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {" "}
                          {formattedCurrency(totalSales.total_sales)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_payment)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_cash)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {formattedCurrency(totalSales.total_change)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Email
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Phone
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Sales Count
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Tax
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Shipping Cost
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Voucher
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Grand Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Cash
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Change
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.code}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formatedDate(item.created_at)}
                          </Text>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.email}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.phone_number}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.code}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_count}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.sales_sum_total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.sales_sum_tax)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.sales_sum_delivery_fee)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(item.sales_sum_nominal_voucher)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.sales_sum_grand_total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(
                              item.payment_header_sum_total_pay
                            )}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(
                              item.payment_header_sum_total_cash
                            )}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(
                              item.payment_header_sum_total_change
                            )}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_tax)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_delivery_fee)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(
                            totalSales.sum_sales_nominal_voucher
                          )}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_grand_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(
                            totalSales.sum_sales_total_payment
                          )}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_total_cash)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_total_change)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      )}
      <br />
      {/* {data.nodes?.length > 0 && <PaginationNav />} */}

      <br />
    </Box>
  );
};

export default ReportSale;
