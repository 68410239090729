import { Box, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useTransition } from "react";
import ListData from "./ListData";
import { useReactToPrint } from "react-to-print";
import { Printer } from "lucide-react";
import { useDispatch } from "react-redux";
import { changeLoading } from "../../../../utils/redux/LoadingReducer";

const Preview = ({ data }) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });
  const [isPending, startTransition] = useTransition();
  const [masterData, setmasterData] = useState([]);
  const reFactorData = async () => {
    await startTransition(() => {
      setmasterData(
        data.reduce(
          (acc, _, i) => (i % 21 === 0 ? [...acc, data.slice(i, i + 21)] : acc),
          []
        )
      );
    });
  };
  useEffect(() => {
    reFactorData().finally(() => {
      dispatch(changeLoading(false));
    });
  }, [data]);
  return (
    <>
      {isPending ? (
        <Spinner />
      ) : (
        masterData.length > 0 && (
          <Box>
            <HStack mb={5} justifyContent={"space-between"}>
              <Text fontFamily={"SemiBold"}>Preview</Text>
              <Button
                isDisabled={data.length < 1}
                colorScheme="teal"
                onClick={handlePrint}
              >
                <HStack spacing={2}>
                  <Printer size={16} />
                  <Text fontFamily={"Medium"}>Print</Text>
                </HStack>
              </Button>
            </HStack>

            <div ref={contentRef}>
              <ListData data={masterData} />
            </div>
          </Box>
        )
      )}
    </>
  );
};

export default Preview;
