import { Text } from "@chakra-ui/react";
import React, { memo } from "react";

const Title = () => {
  return (
    <Text fontFamily={"Semibold"} color="black">
      Label Price List
    </Text>
  );
};

export default memo(Title);
