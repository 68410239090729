import { Button, HStack, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import Download from "./Download";
import Upload from "./Upload";
import { CrossIcon, XCircle } from "lucide-react";

const Header = ({ setdata, data }) => {
  return (
    <HStack w="210mm" spacing={5}>
      <Download />
      <Upload setdata={setdata} data={data} />
    </HStack>
  );
};

export default memo(Header);
